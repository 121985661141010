<template>
  <div class="friends-wrapper">
    <div class="nft-tips">{{knightLevel.tips}}</div>
    <div class="friends-leveal">
      <div class="leveal-item" v-for="item in 6" :key="item">
        <span class="nft-tag" :class="`nft-bg-${item}`">
          <img class="nft-icon" :src="require(`@/static/img/knights/level_${item}.png`)">
          <span>{{ knightLevel[`leval_${item}`] }}</span>
        </span>
        <span class="level-num"> X {{ nftInfo[`levelNum${item}`] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    nftInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {
    knightLevel () {
      return this.$t('knight.level')
    }
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped lang="scss">
.friends-wrapper {
  box-sizing: border-box;
  text-align: center;
  margin: 20px 0 0;
  font-size: 11px;
  .nft-tips {
    font-size: 12px;
    margin-bottom: 25px;
  }
  .friends-leveal {
    background: linear-gradient(
      84deg,
      rgba(163, 123, 66, 0.26) 0%,
      rgba(194, 168, 106, 0.26) 100%
    );
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 5px 0 15px;
    box-sizing: border-box;
    .leveal-item {
      margin-bottom: 15px;
      flex-shrink: 0; /*防止被压缩*/
      color: #fff;
      font-size: 10px;
      .nft-tag {
        display: inline-block;
        min-width: 56px;
        height: 21px;
        line-height: 21px;
        background: linear-gradient(90deg, #a37b42 0%, #c2a86a 100%);
        border-radius: 10px;
        padding: 1px 4px;
        box-sizing: border-box;
        font-size: 11px;
        font-weight: bold;
        color: #12142d;
        .nft-icon {
          width: auto;
          height: 14px;
          vertical-align: -0.15em;
        }
        &.nft-bg-1 {
          background: #8f6d37;
        }
        &.nft-bg-2 {
          background: #997940;
        }
        &.nft-bg-3 {
          background: #a4854a;
        }
        &.nft-bg-4 {
          background: #ae9153;
        }
        &.nft-bg-5 {
          background: #b99d5d;
        }
        &.nft-bg-6 {
          background: #c3a966;
        }
      }
      .level-num {
        width: 34px;
        display: inline-block;
      }
    }
  }
}
</style>
