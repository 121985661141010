<template>
    <div class="nft-page" v-if="!loading">
      <KnightBox :nftInfo="nftInfo"></KnightBox>
      <KnightActive v-if="nftInfo.nftLevel" :nftInfo="nftInfo"></KnightActive>
      <KnightInactive v-else :nftInfo="nftInfo" :buyFlag="buyFlag" @handleBuy="handleBuy"></KnightInactive>
      <!-- <KnightPower></KnightPower> -->
    </div>
</template>

<script>
import KnightBox from './components/KnightBox.vue'
import KnightActive from './components/KnightActive.vue'
import KnightInactive from './components/KnightInactive.vue'
// import KnightPower from './components/KnightPower.vue'
import { queryKnightInfo } from '../../services/knight'
export default {
  name: '',
  components: {
    KnightBox,
    KnightActive,
    KnightInactive
  },
  data () {
    return {
      loading: true,
      nftInfo: {
        address: '',
        price: 0,
        nftNo: '',
        nftLevel: ''
      },
      timer: null,
      buyFlag: false, // 购买标识，购买之后，五分钟内不能点击
      buyTimer: null, // 激活计时器
      buyCuntTime: 300 // 激活缓存时间 5分钟
    }
  },
  computed: {
    buyTips () {
      return this.$t('knight.level.buyTips')
    }
  },
  methods: {
    async setTimer () {
      this.timer = setInterval(() => {
        queryKnightInfo().then(resp => {
          this.nftInfo = resp.result
        }).catch(() => {
          clearInterval(this.timer)
        })
        if (this.nftInfo.nftLevel) {
          clearInterval(this.timer)
          clearInterval(this.buyTimer)
          localStorage.removeItem('activeTime')
        }
      }, 10000)
    },
    async getInfo () {
      this.loading = true
      this.$emit('changeLoading', true)
      const resp = await queryKnightInfo()
      this.$emit('changeLoading', true)
      this.nftInfo = resp.result
      // 没有骑士卡
      if (!this.nftInfo.nftLevel) {
        this.getActiveTime()
        this.setTimer()
      } else {
        // 已有骑士卡
        localStorage.removeItem('activeTime')
        clearInterval(this.timer)
        clearInterval(this.buyTimer)
      }
      this.loading = false
    },
    async handleBuy () {
      if (this.buyFlag) return
      this.buyFlag = true
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      const resp = await this.$web3.transferMeb(this.nftInfo.address, this.nftInfo.price)
      this.$emit('changeLoading', false)
      if (resp.success) {
        this.$toast(this.buyTips)
        setTimeout(() => {
          // 重新获取数据
          this.getInfo()
        }, 1500)
        const activeTime = Date.now()
        localStorage.setItem('activeTime', activeTime)
        this.countDown(this.buyCuntTime)
      } else {
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.buyTimer)
        this.buyTimer = null
        this.buyFlag = false
        this.$toast('fail')
      }
    },
    countDown (time) {
      this.buyFlag = true // 禁止点击
      this.buyTimer = setInterval(() => {
        time = time - 1
        if (time <= 0) {
          // 达到倒计时停止
          clearInterval(this.buyTimer)
          this.buyFlag = false
          localStorage.removeItem('activeTime')
        }
      }, 1000)
    },
    getActiveTime () {
      const preTime = localStorage.getItem('activeTime') // 获取激活按钮时的时间
      if (!preTime) return
      const timeNum = parseInt((Date.now() - (preTime)) / 1000) // 当前时间跟点击按钮时候的时间差，并算成s
      if (timeNum >= this.buyCuntTime) {
        // 如果这个时间差大于7200， 则证明过了2h
        localStorage.removeItem('activeTime')
      } else { // 否则，将剩余的时间传进去，继续倒计时
        this.countDown(this.buyCuntTime - timeNum)
      }
    }
  },
  mounted () {
    this.getInfo()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
    clearInterval(this.buyTimer)
    this.buyTimer = null
  }
}
</script>

<style lang="scss">
.nft-page {
  padding: 50px 20px 32px 20px;
  box-sizing: border-box;
  text-align: center;
  background: linear-gradient(180deg, #1E1713 0%, #3B291C 100%);
  color: #A37B42;
  height: 100vh;
  .title {
    margin: 10px auto;
  }
  div {
    line-height: 1.5em;
  }
}
</style>
