<template>
  <div class="nft-box">
    <img v-if="!nftInfo.nftLevel" class="nft-img" src="@/static/img/knights/nft-card-1.png">
    <img v-else class="nft-img" :src="require(`@/static/img/knights/nft-card-${nftInfo.nftLevel}.png`)">
    <div class="nft-level-star-wrapper" >
      <img v-for="item in nftInfo.nftLevel || 1" :key="item" src="@/static/img/knights/star.png" class="nft-star">
    </div>
    <div class="nft-no">NO.{{nftInfo.nftNo ? nftInfo.nftNo : 'XXXXXXXX'}}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    nftInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped lang="scss">
.nft-box {
  width: 228px;
  height: 400px;
  position: relative;
  font-size: 12px;
  color: #C18B50;
  text-align: center;
  margin: auto;
  position: relative;
  .nft-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 228px;
    height: 400px;
  }
  .nft-level-star-wrapper {
    position: relative;
    display: flex;
    padding-top: 307px;
    box-sizing: border-box;
    .nft-star {
      width: 15px;
      height: auto;
      margin-right: 5px;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
       margin-right: auto;
      }
    }
  }
  .nft-no {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
