<template>
    <div class="knight-inactive">
      <div class="nft-price-warpper">{{nftBuy.price}}: {{nftInfo.price}} MEB</div>
      <div class="nft-tips">{{nftBuy.remainingQuantity}}：{{nftInfo.remainingSales || 0}}</div>
      <div class="buy-btn" :class="{'inactive': !nftInfo.remainingSales || buyFlag}" @click="handleBuy"><span>{{nftBuy.purchaseActivation}}</span></div>
      <!-- <div class="buy-address">轉帳地址：{{nftInfo.address}}</div> -->
      <div class="nft-tips">{{nftBuy.tips}}</div>
    </div>
</template>

<script>
export default {
  name: '',
  props: {
    nftInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    buyFlag: {
      default: () => {
        return false
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    nftBuy () {
      return this.$t('knight.buy')
    }
  },
  methods: {
    async handleBuy () {
      if (!this.nftInfo.remainingSales) return
      this.$emit('handleBuy')
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.knight-inactive {
  padding-top: 30px;
  .nft-price-warpper {
    color: #D2B064;
    font-size: 20px;
    font-weight: bold;
  }
  .buy-btn {
    // width: 310px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    background: linear-gradient(84deg, #A37B42 0%, #C2A86A 100%);
    margin: 25px auto 5px auto;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    color: #302218;
    cursor: pointer;
    position: relative;
    &.inactive {
      opacity: 0.5;
    }
    &.inactive::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .nft-tips {
    font-size: 12px;
  }
}
</style>
